import React, { useState } from "react";
import Loader from "src/components/Loader/Loader";
import NewVendor from "./NewVendor";
import { useNavigate } from "react-router-dom";
import { GetVendorsAndPartners } from "src/services/third-party-risk/vendors-and-partners/vendors";
import TableLayout from "src/layouts/TableLayout";
import { handleSort, lastUpdatedAt, sortRows } from "src/utils/general";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { initialSort, attributeColors, pageSize } from "src/constants/general";
import { Sort } from "src/types/dashboard";
import TablePagination from "src/components/General/TablePagination";

const VendorList = () => {
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [sort, setSort] = useState<Sort>(initialSort);

  const { data: vendors, status: vendorsStatus } =
    GetVendorsAndPartners(pageNumber);

  const metadata = vendors && JSON.parse(vendors.header.metadata);
  const filteredMetadata = metadata?.required.filter((col: string) => {
    if (metadata.properties[col].hidden === "True") return null;
    return col;
  });
  const sortedRows = vendors && sortRows(vendors?.data, sort);

  const totalCount = vendors?.pager.total_results || 0;
  const totalPages = Math.ceil(totalCount / pageSize);
  const beginning =
    pageNumber === 1 ? 1 : pageSize * ((pageNumber || 1) - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  return (
    <section className="flex flex-col flex-grow gap-5">
      {vendors?.data.length > 0 && <NewVendor />}
      {vendorsStatus === "loading" ? (
        <Loader />
      ) : vendors?.data?.length > 0 ? (
        <section className="flex flex-col flex-grow gap-5">
          <TablePagination
            totalPages={totalPages}
            beginning={beginning}
            end={end}
            totalCount={totalCount}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
          <TableLayout fullHeight>
            <thead className="sticky -top-1.5 z-10 bg-gradient-to-b dark:from-expand dark:to-table-header">
              <tr>
                {filteredMetadata?.map((col: string) => {
                  const column = metadata.properties[col];
                  return (
                    <th
                      scope="col"
                      key={col}
                      className="py-3 px-3 last:pr-10 w-full text-left font-semibold"
                    >
                      <article className="capitalize flex gap-10 justify-between">
                        <h4 className="break-all">{column.title}</h4>
                        <button aria-label="sort">
                          <FontAwesomeIcon
                            icon={faSort}
                            className="mt-0.5 dark:text-secondary"
                            onClick={() => handleSort(col, setSort)}
                          />
                        </button>
                      </article>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {sortedRows?.map((row: any, index: number) => {
                return (
                  <tr
                    key={index}
                    data-test="table-row"
                    className="relative p-5 gap-3 cursor-pointer break-words dark:bg-card dark:even:bg-gradient-to-b from-panel to-panel/70 dark:hover:bg-gradient-to-b dark:hover:from-expand dark:hover:to-filter/60"
                    onClick={() => {
                      sessionStorage.GRCCategory = "vendors";
                      navigate(
                        `/third-party-risk/vendors-partners/details?vendor_id=${row.third_party_id}`
                      );
                    }}
                  >
                    {filteredMetadata?.map((col: string, colIndex: number) => {
                      const date = lastUpdatedAt(row.last_updated);

                      return (
                        <td
                          key={`${index}-${colIndex}`}
                          className="relative py-3 px-3 last:pr-16 text-left break-all"
                        >
                          <p
                            className={`${
                              attributeColors[String(row[col]).toLowerCase()]
                            }`}
                          >
                            {col === "last_updated" ? date : String(row[col])}
                          </p>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </TableLayout>
        </section>
      ) : (
        <section className="flex items-center place-content-center gap-10 w-full h-full">
          <img
            src="/grc/third-party-risk-placeholder.svg"
            alt="vendors placeholder"
            className="w-40 h-40"
          />
          <article className="grid gap-3">
            <h4 className="text-xl font-extrabold">Vendors and Partners</h4>
            <h4>No vendors and partners available</h4>
            <NewVendor />
          </article>
        </section>
      )}
    </section>
  );
};

export default VendorList;
