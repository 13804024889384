/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { showVariants } from "src/constants/general";
import { thirdPartyRiskTabs } from "src/constants/grc";
import { motion } from "framer-motion";
import PageLayout from "src/layouts/PageLayout";
import { useGRCStore } from "src/stores/grc";
import { faXmark, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SearchGRC } from "src/services/grc";
import SearchResults from "../RegulationPolicy/SearchResults";
import Vendors from "./Vendors/Vendors";
import Assessments from "./Assessments/Assessments";
import GlobalQuestionsSearchResults from "./Assessments/GlobalQuestions/SearchResults";
import Loader from "src/components/Loader/Loader";

const ThirdPartyRisk = () => {
  const {
    GRCCategory,
    setGRCCategory,
    GRCQuery,
    setGRCQuery,
    GRCQueryOption,
    setGRCQueryOption,
  } = useGRCStore();

  const [selectedAssessmentTab, setSelectedAssessmentTab] =
    useState<string>("assessments");
  const [selectedQuestions, setSelectedQuestions] = useState<string[]>([]);

  const search = SearchGRC();

  const type =
    GRCCategory === "assessments" &&
    selectedAssessmentTab === "global questions"
      ? "tprm-questions"
      : GRCCategory === "assessments"
      ? "vendor"
      : "privacy";

  const handleSearch = () => {
    search.mutate(
      {
        query: GRCQuery,
        type: type,
        searchType: GRCQueryOption,
      },
      {
        onSuccess: () => {
          sessionStorage.GRCCategory = "Third Party Risk";
        },
      }
    );
  };

  useEffect(() => {
    if (
      search.data &&
      (GRCQuery === "" ||
        (GRCCategory !== "assessments" &&
          selectedAssessmentTab !== "global questions" &&
          !search.data[type]))
    )
      search.reset();
  }, [GRCQuery, GRCCategory, selectedAssessmentTab]);

  useEffect(() => {
    sessionStorage.page = "Third Party Risk";
    if (thirdPartyRiskTabs.includes(sessionStorage.GRCCategory))
      setGRCCategory(sessionStorage.GRCCategory);
    else setGRCCategory("vendors");
  }, []);

  return (
    <PageLayout>
      <motion.main
        variants={showVariants}
        initial="hidden"
        animate="visible"
        className="flex flex-col flex-grow gap-5 p-4 w-full h-full text-base overflow-auto scrollbar"
      >
        <article className="flex items-center gap-2">
          <input
            type="input"
            autoComplete="off"
            spellCheck="false"
            placeholder="Search"
            value={GRCQuery}
            onKeyUpCapture={(e) => {
              if (e.key === "Enter" && GRCQuery !== "") handleSearch();
            }}
            onChange={(e) => setGRCQuery(e.target.value)}
            className="p-4 pr-12 w-[30rem] h-10 bg-gradient-to-b dark:bg-main border-t border-b dark:border-know/60 know focus:outline-none"
          />
          <article className="flex items-center gap-2 divide-x dark:divide-checkbox">
            {GRCQuery !== "" && (
              <button
                data-test="clear-query"
                className="red-button"
                onClick={() => setGRCQuery("")}
              >
                <FontAwesomeIcon icon={faXmark} />
              </button>
            )}
            <button
              disabled={GRCQuery === ""}
              className="px-2 dark:disabled:text-signin/30 dark:text-signin dark:hover:text-signin/60 duration-100"
              onClick={handleSearch}
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </button>
          </article>
          <article className="flex items-center gap-5">
            {["metadata"].map((searchType) => {
              return (
                <article
                  key={searchType}
                  className="flex itmes-center gap-2 capitalize"
                >
                  <input
                    type="radio"
                    checked={GRCQueryOption === searchType}
                    className="form-radio w-4 h-4 self-start mt-1 dark:ring-0 dark:text-signin dark:focus:border-signin focus:ring dark:focus:ring-offset-0 dark:focus:ring-signin focus:ring-opacity-50 rounded-full"
                    onChange={() => setGRCQueryOption(searchType)}
                  />
                  <span>Search by {searchType}</span>
                </article>
              );
            })}
          </article>
        </article>
        <nav className="flex flex-wrap items-center gap-5">
          {thirdPartyRiskTabs.map((tab) => {
            return (
              <button
                key={tab}
                className={`px-8 py-2 text-center capitalize border-b-2 ${
                  GRCCategory === tab
                    ? "dark:bg-signin/30 dark:border-signin"
                    : "dark:bg-filter/10 dark:hover:bg-filter/30 duration-100 dark:border-checkbox"
                } rounded-full`}
                onClick={() => {
                  sessionStorage.GRCCategory = tab;
                  setGRCCategory(tab);
                }}
              >
                {tab}
              </button>
            );
          })}
        </nav>

        {search.status === "loading" ? (
          <Loader />
        ) : search.status === "success" ? (
          selectedAssessmentTab === "global questions" ? (
            <GlobalQuestionsSearchResults
              searchData={search.data}
              selectedQuestions={selectedQuestions}
              setSelectedQuestions={setSelectedQuestions}
            />
          ) : (
            <SearchResults searchData={search.data} />
          )
        ) : (
          <>
            {GRCCategory === "vendors" ? (
              <Vendors />
            ) : GRCCategory === "assessments" ? (
              <Assessments
                selectedAssessmentTab={selectedAssessmentTab}
                setSelectedAssessmentTab={setSelectedAssessmentTab}
                selectedQuestions={selectedQuestions}
                setSelectedQuestions={setSelectedQuestions}
              />
            ) : null}
          </>
        )}
      </motion.main>
    </PageLayout>
  );
};

export default ThirdPartyRisk;
