import React from "react";
import VendorAssessment from "./VendorAssessment/VendorAssessment";
import GlobalQuestions from "./GlobalQuestions/GlobalQuestions";
import CustomQuestionSets from "./CustomQuestionSets/CustomQuestionSets";
import PrivacyReview from "./PrivacyReview/PrivacyReview";

const Assessments = ({
  selectedAssessmentTab,
  setSelectedAssessmentTab,
  selectedQuestions,
  setSelectedQuestions,
}: {
  selectedAssessmentTab: string;
  setSelectedAssessmentTab: (selectedAssessmentTab: string) => void;
  selectedQuestions: string[];
  setSelectedQuestions: (selectedQuestions: string[]) => void;
}) => {
  return (
    <section className="flex flex-col flex-grow gap-5">
      <nav className="flex flex-wrap items-center gap-5">
        {[
          "assessments",
          "global questions",
          "custom question sets",
          "privacy review",
        ].map((tab) => {
          return (
            <button
              key={tab}
              className={`px-8 py-2 text-center capitalize border-b-2 ${
                selectedAssessmentTab === tab
                  ? "dark:text-white dark:border-signin"
                  : "dark:text-checkbox dark:hover:text-white dark:border-checkbox"
              }`}
              onClick={() => setSelectedAssessmentTab(tab)}
            >
              {tab}
            </button>
          );
        })}
      </nav>
      {selectedAssessmentTab === "assessments" ? (
        <VendorAssessment />
      ) : selectedAssessmentTab === "global questions" ? (
        <GlobalQuestions
          selectedQuestions={selectedQuestions}
          setSelectedQuestions={setSelectedQuestions}
        />
      ) : selectedAssessmentTab === "custom question sets" ? (
        <CustomQuestionSets />
      ) : (
        <PrivacyReview />
      )}
    </section>
  );
};

export default Assessments;
