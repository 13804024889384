import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import RegularInput from "src/components/Input/RegularInput";
import { AddCustomQuestionSet } from "src/services/third-party-risk/vendors-and-partners/custom-question-sets";

const CreateManualSet = ({
  inputs,
  setInputs,
  selectedQuestions,
}: {
  inputs: any;
  setInputs: (inputs: any) => void;
  selectedQuestions: string[];
}) => {
  const createCustomQuestionSet = AddCustomQuestionSet();

  return (
    <Popover as="article" className="relative inline-block text-left">
      <Popover.Button className="flex items-center gap-2 dark:hover:text-checkbox/60 duration-100">
        <FontAwesomeIcon icon={faUpload} />
        <h4>Create Question Set</h4>
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Popover.Panel className="absolute left-2 grid w-max -mr-5 mt-2 gap-2 origin-top-right focus:outline-none text-sm dark:text-white z-50">
          {({ close }) => (
            <section className="grid gap-3 p-4 dark:bg-filter black-shadow rounded-sm">
              <RegularInput
                label="Name"
                keyName="name"
                inputs={inputs}
                setInputs={setInputs}
                required
              />
              <RegularInput
                label="Description"
                keyName="description"
                inputs={inputs}
                setInputs={setInputs}
              />
              <button
                className="py-3 green-gradient-button"
                onClick={() => {
                  createCustomQuestionSet.mutate({
                    name: inputs.name,
                    description: inputs.description,
                    questionIDs: selectedQuestions,
                  });
                  close();
                }}
              >
                Done
              </button>
            </section>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default CreateManualSet;
